(function (undefined) {
  // eslint-disable-line no-unused-vars
  'use strict';

  var app = angular.module('VebiApp', [
    'ngSanitize',
    'ngRoute',
    'ngAnimate',
    'ngMessages',
    'ngStorage',
    'ngAria',
    'angular-loading-bar',
    'mwl.calendar',
    'checklist-model',
    'uiSwitch',
    'toastr',
    'ivh.treeview',
    'ui.bootstrap',
    'ui.utils.masks',
    'ui.mask',
    'ui.select',
    'ui.tinymce',
    'ui.bootstrap.buttons',
    'idf.br-filters',
    'plupload.directive',
    'as.sortable',
    'angular.viacep',
    'ngImgCrop',
    '19degrees.ngSweetAlert2',
    'ui.validate',
    'btford.socket-io',
    'angularMoment',
    'uiGmapgoogle-maps',
    'as.sortable',
    'underscore',
    'dndLists',
  ]);

  app.constant('ApiUrl', window.GestConfig.ApiUrl);
  app.constant('LocalStorageName', 'VebiAuth');

  app.config([
    '$routeProvider',
    '$httpProvider',
    '$locationProvider',
    '$qProvider',
    'calendarConfig',
    'plUploadServiceProvider',
    'uiSelectConfig',
    'uiGmapGoogleMapApiProvider',
    function (
      $routeProvider,
      $httpProvider,
      $locationProvider,
      $qProvider,
      calendarConfig,
      plUploadServiceProvider,
      uiSelectConfig,
      uiGmapGoogleMapApiProvider
    ) {
      //Prefixo para as rotas
      $locationProvider.hashPrefix('');

      //Interceptors
      $httpProvider.interceptors.push('RedirectInterceptor');
      $httpProvider.interceptors.push('AuthorizationInterceptor');

      //Routes
      $routeProvider.caseInsensitiveMatch = true;
      $routeProvider.otherwise({ redirectTo: '/login' });

      //angular-bootstrap-calendar
      calendarConfig.allDateFormats.moment.date.hour = 'HH:mm';
      calendarConfig.i18nStrings.eventsLabel = 'Eventos';

      //plupload
      plUploadServiceProvider.setConfig(
        'flashPath',
        'bower_components/plupload-angular-directive/dist/plupload.flash.swf'
      );
      plUploadServiceProvider.setConfig(
        'silverLightPath',
        'bower_components/plupload-angular-directive/dist/plupload.silverlight.xap'
      );

      //tinyMCE
      window.tinyMCE.baseURL = '/bower_components/tinymce/';

      //ui-select
      uiSelectConfig.theme = 'bootstrap';

      //Possibly unhandled rejection: undefined (ao cancelar modal e nao ter o metodo cancelar)
      $qProvider.errorOnUnhandledRejections(false);
    },
  ]);

  app.run([
    '$rootScope',
    '$templateCache',
    '$route',
    '$timeout',
    '$window',
    '$http',
    '$location',
    '$uibModalStack',
    '$localStorage',
    'LocalStorageName',
    'amMoment',
    'AuthFactory',
    function (
      $rootScope,
      $templateCache,
      $route,
      $timeout,
      $window,
      $http,
      $location,
      $uibModalStack,
      $localStorage,
      LocalStorageName,
      amMoment,
      AuthFactory
    ) {
      amMoment.changeLocale('pt-br');

      $rootScope.$on('$routeChangeStart', function (event, next, current) {
        // Fecha qualquer modal que esteja aberto, para trocar de pagina
        $uibModalStack.dismissAll();

        if (next.authorize && !AuthFactory.isAutenticated) {
          // Redireciona para a tela de login
          $location.path('/login');
          return;
        }

        if (
          next.roles &&
          next.roles.length &&
          !AuthFactory.HasPermission(next.roles)
        ) {
          // Redireciona para a tela de acesso negado
          $location.path('/acesso-negado');
          return;
        }

        if (typeof current !== 'undefined') {
          $templateCache.remove(current.templateUrl);

          if (!$window.loading_screen) {
            $window.loading_screen = pleaseWait({
              logo: 'wwwroot/imagens/logo_gestcom_branco.png',
              backgroundColor: 'rgba(0, 0, 0, 0.40)',
              loadingHtml:
                '<div class="sk-spinner sk-spinner-wave"><div class="sk-rect1"></div><div class="sk-rect2"></div><div class="sk-rect3"></div><div class="sk-rect4"></div><div class="sk-rect5"></div></div>',
            });
          }

          // Ajustes Limitless
          /*
        Ao utilizar a sidebar navigation, no modo XS o limitless remove o elemento para fora do content.
        Ao carregar a pagina ele insere novamente o sidebar e fica duplicado.
        Solução: Sempre que trocar de pagina remove todas as sidebars para que a nova seja inserida.
        */
          $('.sidebar-detached').remove();
        }
      });

      $rootScope.$on('$includeContentLoaded', function (event, templateName) {
        switch (templateName) {
        case '/Views/Shared/navbar.html':
          initNavBar(); // eslint-disable-line no-undef
          break;
        }
      });

      $rootScope.$on('$routeChangeSuccess', function (
        currentRoute,
        previousRoute
      ) {
        // eslint-disable-line no-unused-vars
        $rootScope.title = $route.current.title;
        $rootScope.detached = $route.current.detached;

        $rootScope.ShowMenu =
          $route.current.ShowMenu === undefined
            ? true
            : $route.current.ShowMenu;

        if ($window.loading_screen) {
          $window.loading_screen.finish();
          $window.loading_screen = undefined;

          // Ajustes Limitless
          // Sempre oculta o menu no celular ao trocar de rota.
          $('body').removeClass(
            'sidebar-mobile-main sidebar-mobile-secondary sidebar-mobile-opposite sidebar-mobile-detached'
          );

          // if ($rootScope.detached) {
          //   // Sempre que trocar a rota executa a trigger do limitless para ajustes de telas etc.
          //   setTimeout(function () { $(window).trigger('resize'); }, 1000);
          // }

          setTimeout(function () {
            $(window).trigger('resize');
          }, 1000);
        }
      });
    },
  ]);
})();

(function (undefined) {
  // eslint-disable-line no-unused-vars
  'use strict';

  var app = angular.module('VebiApp');

  app.factory('CondicaoPagamentoFactory', [
    '$http',
    'ApiUrl',
    function ($http, ApiUrl) {
      var dataFactory = {};

      dataFactory.Get = function () {
        return $http.get(ApiUrl + '/Cadastro/Condicao-Pagamento/Pedido');
      };

      return dataFactory;
    },
  ]);
})();

(function (undefined) {
  // eslint-disable-line no-unused-vars
  'use strict';

  var app = angular.module('VebiApp');

  app.factory('PedidoFactory', [
    '$http',
    'ApiUrl',
    function ($http, ApiUrl) {
      var dataFactory = {};

      dataFactory.GetPedido = function (includes) {
        return $http.get(ApiUrl + '/Pedido/Revenda', { params: includes });
      };

      dataFactory.GetCondicaoPagamento = function () {
        return $http.get(ApiUrl + '/Cadastro/Condicao-Pagamento/Revenda');
      };

      dataFactory.Finalizar = function (vm) {
        return $http.post(ApiUrl + '/Pedido/Finalizar', vm);
      };
      return dataFactory;
    },
  ]);
})();

(function (undefined) {
  // eslint-disable-line no-unused-vars
  'use strict';

  var app = angular.module('VebiApp');

  app.factory('ProdutoFactory', [
    '$http',
    'ApiUrl',
    function ($http, ApiUrl) {
      var dataFactory = {};

      dataFactory.GetByFiltro = function (filter) {
        return $http.get(ApiUrl + '/Cadastro/Produto', { params: filter });
      };

      dataFactory.Adicionar = function (vm) {
        return $http.post(ApiUrl + '/Pedido/Item', vm);
      };

      dataFactory.AtualizarQuantidade = function (vm) {
        return $http.put(ApiUrl + '/Pedido/Item', vm);
      };

      dataFactory.Delete = function (itemID) {
        return $http.delete(ApiUrl + '/Pedido/Item/' + itemID);
      };

      return dataFactory;
    },
  ]);
})();

(function (undefined) { // eslint-disable-line no-unused-vars
  'use strict';

  var app = angular.module('VebiApp');

  app.factory('AgendamentoBlockFactory', ['SocketIO', function (SocketIO) {
    var dataFactory = {};

    dataFactory.ScreenLock = function(vm, title, emptyMsg, cb) {
      var loadingHtml = '<p class="loading-title" style="font-size: 2em; color: #ffffff">'+(title || '')+'</p>';
      loadingHtml += '<div class="progress progress-xxs" style="margin-left: 25%; margin-right: 25%;"><div class="progress-bar progress-bar-info progress-bar-striped active" style="width: ' + vm.Progress + '%"></div></div>';

      var loadMessage = emptyMsg || 'Aguardando processamento...';

      loadingHtml += '<p class="loading-message" style="color: #fff;">' + loadMessage + '</p>';

      var loading_screen = pleaseWait(
        {
          logo: 'wwwroot/imagens/logo_gestcom_branco.png',
          backgroundColor: 'rgba(0, 0, 0, 0.40)',
          loadingHtml: loadingHtml
        }
      );

      SocketIO.on('Agendamento:Update', function (agendamento) {
        if (agendamento.AgendamentoID !== vm.AgendamentoID) { return; }

        if (agendamento.ProgressMessage) {
          document.querySelector('.loading-title').innerHTML = agendamento.ProgressMessage;
        }

        document.querySelector('.pg-loading-html .progress .progress-bar').style = 'width: ' + agendamento.Progress + '%';
        var element = document.querySelector('.pg-loading-html .loading-message');
        if (element) {
          element.innerHTML = agendamento.Progress + '%';
        }
      });

      SocketIO.on('Agendamento:Excluir', function (agendamento) {
        if (agendamento.AgendamentoID !== vm.AgendamentoID) { return; }
        loading_screen.finish();
      });

      SocketIO.on('Agendamento:Finish', function (agendamento) {
        if (agendamento.AgendamentoID !== vm.AgendamentoID) { return; }
        loading_screen.finish();

        if (cb) { cb(agendamento); }
      });
    };

    return dataFactory;
  }]);
})();

(function (undefined) {
  // eslint-disable-line no-unused-vars
  'use strict';

  var app = angular.module('VebiApp');

  app.factory('AuthFactory', [
    '$http',
    '$window',
    '$q',
    '$localStorage',
    '$timeout',
    'LocalStorageName',
    'ApiUrl',
    'BrowserNotificationFactory',
    function (
      $http,
      $window,
      $q,
      $localStorage,
      $timeout,
      LocalStorageName,
      ApiUrl,
      BrowserNotificationFactory
    ) {
      // Variaveis globais
      var loginObservables = [];
      var logoutObservables = [];
      var refreshTokenObservables = [];
      var refreshTokenTimeout = undefined;

      var dataFactory = {
        isAutenticated: false,
        Payload: {},
        Token: undefined,
        Expiration: undefined,
        GetUserInfo: function () {
          return {
            Nome: this.Payload['Nome'],
            Email: this.Payload['Email'],
            CNPJ: this.Payload['CNPJ'],
            Login: this.Payload['Email'],
            OperadorID: this.Payload['ID'],
          };
        },
      };

      var ConfigAuth = function () {
        var storageData = $localStorage[LocalStorageName];

        dataFactory.isAutenticated = true;
        dataFactory.Token = storageData.Token;
        dataFactory.Expiration = new Date(storageData.Expiration);

        // add jwt token to auth header for all requests made by the $http service
        $http.defaults.headers.common.Authorization =
          'Bearer ' + dataFactory.Token;

        // set payload to dataFactory
        var base64Url = dataFactory.Token.split('.')[1];
        var base64 = base64Url.replace('-', '+').replace('_', '/');
        dataFactory.Payload = JSON.parse($window.atob(base64));

        // Refresh Token
        if (dataFactory.isAutenticated) {
          var timeRemain = dataFactory.TokenTimeRemain();

          if (timeRemain <= 0) {
            // Se não tem mais tempo efetua logout automaticamente.
            dataFactory.Logout();
          } else {
            // Agenda o proximo refresh token para 5min ou o tempo que resta
            refreshTokenTimeout = $timeout(
              dataFactory.RefreshToken,
              (timeRemain > 5 ? 1 : timeRemain) * 60000
            );
          }
        }
      };

      var onLoginHandle = function () {
        // Sentry: Configura para pegar as informacoes do usuario logado
        // Raven.setUserContext({
        //   id: dataFactory.GetUserInfo().OperadorID,
        //   username: dataFactory.GetUserInfo().Nome,
        //   email: dataFactory.GetUserInfo().Email,
        //   Login: dataFactory.GetUserInfo().Login
        // });

        // Percorre todos os observadores informando a alteração
        for (var i = 0; i < loginObservables.length; i++) {
          var itemObservable = loginObservables[i];
          itemObservable(dataFactory.GetUserInfo());
        }

        onRefreshTokenHandle();
      };

      var onLogoutHandle = function () {
        // Sentry: Remove as informações do usuário logado
        //Raven.setUserContext({});
        //Raven.clearContext();

        // Cancelar Refresh Token
        if (refreshTokenTimeout) {
          $timeout.cancel(refreshTokenTimeout);
        }

        // Percorre todos os observadores informando a alteração
        for (var i = 0; i < logoutObservables.length; i++) {
          var itemObservable = logoutObservables[i];
          itemObservable(dataFactory.GetUserInfo());
        }
      };

      var onRefreshTokenHandle = function () {
        // Percorre todos os observadores informando a alteração
        for (var i = 0; i < refreshTokenObservables.length; i++) {
          var itemObservable = refreshTokenObservables[i];
          itemObservable(dataFactory.TokenTimeRemain());
        }
      };

      dataFactory.TokenTimeRemain = function () {
        var currentTime = moment();
        var tokenExpiration = moment(dataFactory.Expiration);
        var timeRemain = tokenExpiration.diff(currentTime, 'minutes');

        return timeRemain;
      };

      dataFactory.Login = function (vm) {
        var deferred = $q.defer();

        $http
          .post(ApiUrl + '/login', vm)
          .then(function (response) {
            $localStorage[LocalStorageName] = response.data;

            ConfigAuth();
            onLoginHandle();

            // Solicita para habilitar as notificaçoes caso já nao tenha.
            BrowserNotificationFactory.RequestPermission();

            deferred.resolve();
          })
          .catch(function (response) {
            deferred.reject(response);
          });

        return deferred.promise;
      };

      dataFactory.RefreshToken = function () {
        var deferred = $q.defer();

        $http
          .post(ApiUrl + '/login/refresh-token')
          .then(function (response) {
            $localStorage[LocalStorageName] = response.data;

            ConfigAuth();
            onRefreshTokenHandle();
            deferred.resolve();
          })
          .catch(function () {
            deferred.reject();
          });

        return deferred.promise;
      };

      dataFactory.HasPermission = function (roles) {
        if (!roles || !dataFactory.Payload.Roles) {
          return false;
        }

        var final = [];
        for (var i = 0; i < roles.length; i++) {
          if (dataFactory.Payload.Roles.indexOf(roles[i]) > -1) {
            final.push(roles[i]);
          }
        }

        return final.length > 0;
      };

      dataFactory.Logout = function () {
        var deferred = $q.defer();

        //dataFactory = { isAutenticated: false, Payload:{}, Token:  undefined, Expiration: undefined };
        dataFactory.isAutenticated = false;
        dataFactory.Payload = {};
        dataFactory.Token = undefined;
        dataFactory.Expiration = undefined;

        // remove user from local storage and clear http auth header
        //delete $localStorage[LocalStorageName];
        $localStorage.$reset();

        $http.defaults.headers.common.Authorization = '';

        onLogoutHandle();

        deferred.resolve();
        return deferred.promise;
      };

      dataFactory.ChangePassword = function (vm) {
        var deferred = $q.defer();

        $http
          .post(ApiUrl + '/login/change-password', vm)
          .then(function (response) {
            $localStorage[LocalStorageName] = response.data;

            ConfigAuth();
            deferred.resolve();
          })
          .catch(function (response) {
            deferred.reject(response);
          });

        return deferred.promise;
      };

      dataFactory.ForgotPasswordEmail = function (vm) {
        return $http.post(ApiUrl + '/login/reset-password/email', vm);
      };

      dataFactory.ForgotPassword = function (vm) {
        return $http.post(ApiUrl + '/Login/reset-password', vm);
      };

      dataFactory.OnLogin = function (fn) {
        loginObservables.push(fn);

        if (dataFactory.isAutenticated) {
          fn(dataFactory.GetUserInfo());
        }
      };

      dataFactory.OnLogout = function (fn) {
        logoutObservables.push(fn);
      };

      dataFactory.OnRefreshToken = function (fn) {
        refreshTokenObservables.push(fn);

        if (dataFactory.isAutenticated) {
          fn(dataFactory.TokenTimeRemain());
        }
      };

      // Configura o login quando reload
      if ($localStorage[LocalStorageName]) {
        ConfigAuth();
        onLoginHandle();
      }

      return dataFactory;
    },
  ]);
})();

(function (undefined) { // eslint-disable-line no-unused-vars
  'use strict';

  var app = angular.module('VebiApp');

  // REMOVE O TOKEN AUTHORIZATION SE O REQUEST NAO FOR PARA A API
  app.factory('AuthorizationInterceptor', ['ApiUrl', function (ApiUrl) {
    return {
      request: function (config) {

        if (config.headers.Authorization && !config.url.startsWith(ApiUrl)) {
          delete config.headers.Authorization;
        }

        return config;
      }
    };
  }]);
})();

(function (undefined) { // eslint-disable-line no-unused-vars
  'use strict';

  var app = angular.module('VebiApp');

  app.factory('BrowserNotificationFactory', [function () {
    var dataFactory = {};

    dataFactory.HasPermission = function () {
      return (('Notification' in window) && Notification.permission === 'granted');
    };

    dataFactory.RequestPermission = function() {
      if (dataFactory.HasPermission() || !('Notification' in window)) { return; }

      Notification.requestPermission(function(permission) {}); // eslint-disable-line no-unused-vars
    };

    dataFactory.ShowNotification = function(titulo, options) {
      if (!dataFactory.HasPermission()) { return; }

      var notification = new Notification(titulo, options); // eslint-disable-line no-unused-vars
    };

    return dataFactory;
  }]);
})();

(function (undefined) { // eslint-disable-line no-unused-vars
  'use strict';

  var app = angular.module('VebiApp');

  app.factory('DownloadFileFactory', ['$http', '$window', function ($http, $window) {
    var dataFactory = {
      DownloadFile: function (fileLink, fileName) {
        $http({
          method: 'get',
          url: fileLink,
          cache: 'true'
        })
          .then(function (file) {
            var link = angular.element(document.createElement('a'));

            var blob = new Blob([file.data], {type: 'text/plain'});

            var url = $window.URL.createObjectURL(blob);

            link.attr('href', url);
            link.attr('download', fileName);

            link[0].click();

            $window.URL.revokeObjectURL(url);
          });
      }
    };

    return dataFactory;
  }]);

})();

(function (undefined) {
  // eslint-disable-line no-unused-vars
  'use strict';

  var app = angular.module('VebiApp');

  app.constant('Enum', {
    Confirmar: [
      { Value: true, Name: 'Sim' },
      { Value: false, Name: 'Não' },
    ],
    Situacao: [
      { Value: 1, Name: 'Ativo' },
      { Value: 0, Name: 'Cancelado' },
    ],
    PedidoReservado: [
      { Value: 0, Name: 'Nenhum' },
      { Value: 1, Name: 'Ativo' },
      { Value: 2, Name: 'Finalizado' },
    ],
    Get: function (enumeracao, value) {
      if (value === null) {
        return undefined;
      }

      for (var i = 0; i < enumeracao.length; i++) {
        if (enumeracao[i].Value === value) {
          return enumeracao[i];
        }
      }
      return undefined;
    },
  });
})();

(function (undefined) {
  // eslint-disable-line no-unused-vars
  'use strict';

  var app = angular.module('VebiApp');

  app.factory('GenericBlockFactory', [
    function () {
      var dataFactory = {};

      dataFactory.Finish = function () {
        dataFactory.loading_screen.finish();
      };

      dataFactory.Start = function (title, emptyMsg) {
        dataFactory.loadingHtml = '';

        if (title) {
          dataFactory.loadingHtml +=
            '<p class="loading-title" style="font-size: 2em">' + title + '</p>';
        }

        var loadMessage = emptyMsg || 'Aguarde';

        dataFactory.loadingHtml +=
          '<p class="loading-message">' + loadMessage + '</p>';

        dataFactory.loading_screen = pleaseWait({
          logo: 'wwwroot/imagens/logo_gestcom_branco.png',
          backgroundColor: 'rgba(0, 0, 0, 0.40)',
          loadingHtml: dataFactory.loadingHtml,
        });
      };

      return dataFactory;
    },
  ]);
})();

(function (undefined) { // eslint-disable-line no-unused-vars
  'use strict';

  var app = angular.module('VebiApp');

  app.factory('GoogleMapsFactory', ['$http', function ($http) {
    var dataFactory = {};

    dataFactory.BuscaLatitudeLongitude = function (imovel) {

      var dados = {
        address: imovel.Logradouro.Tipo + ' ' + imovel.Logradouro.Descricao + ' ' + imovel.Numero + ', ' + imovel.Bairro.Descricao + ' - ' + imovel.Municipio.Descricao + ' - , brasil',
        key: 'AIzaSyAKdQGL35OmlSAY7B-etH5nMxkJINj7x4o'
      };

      return $http.get('https://maps.googleapis.com/maps/api/geocode/json', { params: dados });
    };

    return dataFactory;
  }]);
})();

(function (undefined) {
  // eslint-disable-line no-unused-vars
  "use strict";

  var app = angular.module("VebiApp");

  app.factory("RedirectInterceptor", [
    "$rootScope",
    "$q",
    "$location",
    function ($rootScope, $q, $location) {
      return {
        responseError: function (response) {
          if (response.status === 401) {
            $location.path("/logout");
            return $q.reject(response);
          }

          return $q.reject(response);
        },
      };
    },
  ]);
})();

(function (undefined) { // eslint-disable-line no-unused-vars
  'use strict';

  var app = angular.module('VebiApp');

  app.factory('ShareDataFactory', [function () {
    var _data = undefined;

    var dataFactory = {
      Get: function() {
        var result = angular.copy(_data);
        _data = undefined;

        return result;
      },
      Set: function(vm) {
        _data = vm;
      },
    };

    return dataFactory;
  }]);
})();

(function (undefined) {
  // eslint-disable-line no-unused-vars
  'use strict';

  var app = angular.module('VebiApp');

  app.factory('SocketIO', [
    'socketFactory',
    'AuthFactory',
    function (socketFactory, AuthFactory) {
      // Mantem as ações solicitadas eqto o script do socket ainda nao estiver sido carregado
      var offlineActions = [];

      // Cria um objeto fake para armazenar as requisições offline
      var dataFactory = {
        on: function (name, fn) {
          offlineActions.push({ name: name, fn: fn });
        },
        addListener: function () {},
        removeListener: function (name) {
          for (var i = 0; i < offlineActions.length; i++) {
            if (offlineActions[i].name === name) {
              // Se o item foi adicionado pelo metodo On, remove da lista
              offlineActions.splice(i, 1);
              break;
            }
          }
        },
        removeAllListeners: function () {},
        emit: function (name, value) {}, // eslint-disable-line  no-unused-vars
        forward: function () {},
      };

      /**************************************************************************************
       * Adiciona o script do socket-io dinamicamente
       ***************************************************************************************/
      /*
       *var socket_io_script = document.createElement('script');
       *socket_io_script.setAttribute(
       *  'src',
       *  window.GestConfig.SocketUrl + '/socket.io/socket.io.js'
       *);
       */

      /**************************************************************************************
       * Quando o script for adicionado
       ***************************************************************************************/
      /*
       *      socket_io_script.onload = function () {
       *        // Configura a conexão com o socket
       *        var socketConnection = window.io(
       *          window.GestConfig.SocketUrl + '/revendas',
       *          { autoConnect: false }
       *        );
       *
       *        // Quando o usuário logar no sistema
       *        // Adiciona as informaçoes do usuário logado ao socket e efetua a conexão
       *        AuthFactory.OnLogin(function (vm) {
       *          socketConnection.io.opts.query = vm;
       *          socketConnection.open();
       *        });
       *
       *        // Quando o usuário sair do sistema
       *        // Remove as informaçoes do usuário e fecha a conexão
       *        AuthFactory.OnLogout(function () {
       *          socketConnection.close();
       *          socketConnection.io.opts.query = {};
       *        });
       *
       *        // Altera o retorno do factory para o socket original
       *        var siof = socketFactory({ ioSocket: socketConnection });
       *
       *        dataFactory.on = siof.on;
       *        dataFactory.addListener = siof.addListener;
       *        dataFactory.removeListener = siof.removeListener;
       *        dataFactory.removeAllListeners = siof.removeAllListeners;
       *        dataFactory.emit = siof.emit;
       *        dataFactory.forward = siof.forward;
       *
       *        // Executa as ações offline
       *        for (var i = 0; i < offlineActions.length; i++) {
       *          dataFactory.on(offlineActions[i].name, offlineActions[i].fn);
       *        }
       *      };
       */

      /*
       *document.body.appendChild(socket_io_script);
       */

      return dataFactory;
    },
  ]);
})();

(function (undefined) { // eslint-disable-line no-unused-vars
  'use strict';

  var app = angular.module('VebiApp');

  app.factory('UtilsFactory', [function () {
    var dataFactory = {};

    dataFactory.isEmpty = function (val) {
      return (val === undefined || val == null || val.length <= 0) ? true : false;
    };

    return dataFactory;
  }]);
})();

(function (undefined) { // eslint-disable-line no-unused-vars
  'use strict';

  var app = angular.module('VebiApp');

  app.filter('mask', function () {
    var cache = {};
    var maskDefinitions = {
      '9': /\d/,
      'A': /[a-zA-Z]/,
      '*': /[a-zA-Z0-9]/
    };
    function getPlaceholderChar() {
      return '_';
    }
    function processRawMask(mask) {
      if (cache[mask]) return cache[mask];
      var characterCount = 0;

      var maskCaretMap = [];
      var maskPatterns = [];
      var maskPlaceholder = '';
      var minRequiredLength = 0;
      if (angular.isString(mask)) {


        var isOptional = false,
          numberOfOptionalCharacters = 0,
          splitMask = mask.split('');

        angular.forEach(splitMask, function (chr, i) {
          if (maskDefinitions[chr]) {

            maskCaretMap.push(characterCount);

            maskPlaceholder += getPlaceholderChar(i - numberOfOptionalCharacters);
            maskPatterns.push(maskDefinitions[chr]);

            characterCount++;
            if (!isOptional) {
              minRequiredLength = minRequiredLength + 1;
            }

            isOptional = false;
          }
          else if (chr === '?') {
            isOptional = true;
            numberOfOptionalCharacters++;
          }
          else {
            maskPlaceholder += chr;
            characterCount++;
          }
        });
      }
      // Caret position immediately following last position is valid.
      maskCaretMap.push(maskCaretMap.slice().pop() + 1);
      return cache[mask] = { maskCaretMap: maskCaretMap, maskPlaceholder: maskPlaceholder };
    }

    function maskValue(unmaskedValue, maskDef) {
      unmaskedValue = unmaskedValue || '';
      var valueMasked = '',
        maskCaretMapCopy = maskDef.maskCaretMap.slice();

      angular.forEach(maskDef.maskPlaceholder.split(''), function (chr, i) {
        if (unmaskedValue.length && i === maskCaretMapCopy[0]) {
          valueMasked += unmaskedValue.charAt(0) || '_';
          unmaskedValue = unmaskedValue.substr(1);
          maskCaretMapCopy.shift();
        }
        else {
          valueMasked += chr;
        }
      });
      return valueMasked;

    }

    return function (value, mask) {
      var maskDef = processRawMask(mask);
      var maskedValue = maskValue(value, maskDef);
      return maskedValue;
    };
  });

})();



(function (undefined) {
  // eslint-disable-line no-unused-vars
  'use strict';

  var app = angular.module('VebiApp');

  app.factory('ParametrizacaoFactory', [
    '$http',
    'ApiUrl',
    function ($http, ApiUrl) {
      var dataFactory = {};

      dataFactory.GetPDFMakeDocument = function (titulo, subtitulo) {
        return $http.post(ApiUrl + '/Cadastro/Parametrizacao/PDFMakeDocument', {
          Titulo: titulo,
          SubTitulo: subtitulo,
        });
      };

      return dataFactory;
    },
  ]);
})();

// eslint-disable-next-line no-unused-vars
(function (undefined) {
  'use strict';

  var app = angular.module('VebiApp');

  app.config([
    '$routeProvider',
    function ($routeProvider) {
      $routeProvider
        .when('/Login', {
          title: 'Login',
          controller: 'LoginController',
          controllerAs: 'ctrl',
          templateUrl: 'Views/Account/Login.html',
          authorize: false,
          roles: [],
          ShowMenu: false,
        })
        .when('/Login/recuperar-senha', {
          title: 'Recuperar Senha',
          controller: 'RecuperarSenhaController',
          controllerAs: 'ctrl',
          templateUrl: 'Views/Account/RecuperarSenha.html',
          authorize: false,
          roles: [],
          ShowMenu: false,
        })
        .when('/Logout', {
          title: 'Logout',
          controller: 'LogoutController',
          controllerAs: 'ctrl',
          templateUrl: 'Views/Account/Logout.html',
          authorize: false,
          roles: [],
          ShowMenu: false,
        })
        .when('/alterar-senha', {
          title: 'Configurações',
          controller: 'ConfiguracoesSenhaController',
          detached: true,
          controllerAs: 'ctrl',
          templateUrl: 'Views/Account/ConfiguracoesSenha.html',
        });
    },
  ]);

  //Controllers
  app.controller('LoginController', [
    '$location',
    'sweetAlert',
    'AuthFactory',
    function ($location, sweetAlert, AuthFactory) {
      var $public = this;
      var $private = {};

      $public.Enviar = function () {
        $public.invalid = false;
        $public.Loading = true;
        $public.Login.Tipo = 0;

        AuthFactory.Login($public.Login)
          .then(function () {
            $location.path('/home');
          })
          .catch(function (response) {
            $public.invalid = true;
            $public.Loading = false;
            $public.Login.Password = undefined;

            switch (response.status) {
            case 404:
              sweetAlert.swal(
                'Atenção!',
                'Usuário inválido ou cancelado.',
                'error'
              );
              break;
            case 406:
              sweetAlert.swal(
                'Atenção!',
                'Verifique o preenchimento de todos os campos.',
                'warning'
              );
              break;
            case 409:
              sweetAlert.swal('Atenção!', 'Login inválido', 'error');
              break;
            case 403:
              sweetAlert.swal(
                'Atenção!',
                'Usuário bloqueado, entre em contato com o administrador.',
                'warning'
              );
              break;
            }
          });
      };

      $private.Init = function () {
        if (AuthFactory.isAutenticated) {
          $location.path('/home');
          return;
        }
      };

      $private.Init();
    },
  ]);

  app.controller('LogoutController', [
    '$location',
    'AuthFactory',
    function ($location, AuthFactory) {
      AuthFactory.Logout();
      $location.path('/login');
    },
  ]);

  app.controller('RecuperarSenhaController', [
    '$location',
    'sweetAlert',
    'AuthFactory',
    function ($location, sweetAlert, AuthFactory) {
      var $public = this;
      var $private = {};

      $public.SendForgotPasswordEmail = function () {
        $public.Loading = true;
        var vm = angular.copy($public.ForgotPasswordEmail);
        vm.Tipo = 0;

        AuthFactory.ForgotPasswordEmail(vm)
          .then(function () {
            sweetAlert.swal(
              'Atenção!',
              'Verifique o código de acesso enviado em seu email',
              'success'
            );

            $public.Loading = false;
            $public.CurrentStep = $public.Steps.ChangePassword;
          })
          .catch(function (response) {
            $public.Loading = false;

            switch (response.status) {
            case 404:
              sweetAlert.swal('Atenção!', 'Operador não encontrado', 'error');
              break;
            case 409:
              sweetAlert.swal(
                'Atenção!',
                'O operador não tem email cadastrado, entre em contato com o administrador do sistema.',
                'error'
              );
              break;
            }
          });
      };

      $public.SendForgotPassword = function () {
        $public.Loading = true;

        var vm = angular.copy($public.ForgotPassword);
        vm.Email = $public.ForgotPasswordEmail.Email;
        vm.Tipo = 0;

        AuthFactory.ForgotPassword(vm)
          .then(function () {
            sweetAlert.swal(
              'Sucesso!',
              'Senha alterada com sucesso!!!',
              'success'
            );

            $location.path('/home');
          })
          .catch(function (response) {
            $public.Loading = false;

            switch (response.status) {
            case 404:
              sweetAlert.swal('Atenção!', 'Código inválido.', 'error');
              break;
            }
          });
      };

      $public.ValidarSenha = function (password) {
        if (!password) return true;
        if (password.length < 6) return false;

        var letrasMaiusculas = /[A-Z]/;
        var letrasMinusculas = /[a-z]/;
        var numeros = /[0-9]/;
        var caracteresEspeciais = /[!|@|#|$|%|^|&|*|(|)|-|_]/;

        if (
          !letrasMaiusculas.test(password) ||
          !letrasMinusculas.test(password) ||
          !numeros.test(password) ||
          !caracteresEspeciais.test(password)
        )
          return false;

        return true;
      };

      $private.Init = function () {
        if (AuthFactory.isAutenticated) {
          $location.path('/home');
          return;
        }

        $public.Steps = { SendEmail: 0, ChangePassword: 1 };
        $public.CurrentStep = $public.Steps.SendEmail;
      };

      $private.Init();
    },
  ]);

  app.controller('ConfiguracoesSenhaController', [
    '$window',
    '$http',
    '$scope',
    '$location',
    'AuthFactory',
    'sweetAlert',
    function ($window, $http, $scope, $location, AuthFactory, sweetAlert) {
      var $public = this;
      var $private = {};

      $public.ValidaSenha = function () {
        if (
          !$public.ResetPassword.NovaSenha ||
          $public.ResetPassword.NovaSenha.length < 6
        ) {
          return false;
        }
        return (
          $public.ResetPassword.NovaSenha &&
          $public.ResetPassword.NovaSenha ===
            $public.ResetPassword.ConfirmarNovaSenha
        );
      };

      $public.Enviar = function () {
        $public.isLoading = true;

        AuthFactory.ChangePassword($public.ResetPassword)
          .then(function () {
            sweetAlert.swal(
              'Sucesso!',
              'Senha alterada com sucesso',
              'success'
            );

            $location.path('/home');
          })
          .catch(function (response) {
            switch (response.status) {
            case 406:
              sweetAlert.swal(
                'Atenção!',
                'Verifique o preenchimento de todos os campos',
                'error'
              );
              break;
            case 409:
              sweetAlert.swal(
                'Atenção!',
                'A senha atual é inválida',
                'error'
              );
              break;
            }
          });
      };

      $private.Init = function () {
        $public.Title = 'Alterar senha';
        $public.ResetPassword = {
          Tipo: 0,
          NovaSenha: undefined,
          ConfirmarNovaSenha: undefined,
        };
      };

      $private.Init();
    },
  ]);
})();

// eslint-disable-next-line no-unused-vars
(function (undefined) {
  'use strict';

  var app = angular.module('VebiApp');

  //Rotas
  app.config([
    '$routeProvider',
    function ($routeProvider) {
      $routeProvider.when('/pedido', {
        title: 'Meu pedido',
        controller: 'PedidoController',
        controllerAs: 'ctrl',
        templateUrl: 'Views/Pedido/index.html',
        authorize: true,
        roles: [],
      });
      $routeProvider.when('/pedido/enviado', {
        title: 'Pedido enviado',
        controller: 'PedidoEnviadoController',
        controllerAs: 'ctrl',
        templateUrl: 'Views/Pedido/Sucesso.html',
        authorize: true,
        roles: [],
      });
    },
  ]);

  //Controllers
  app.controller('PedidoController', [
    '$location',
    '$filter',
    '$uibModal',
    'sweetAlert',
    'AuthFactory',
    'ProdutoFactory',
    'ParametrizacaoFactory',
    'PedidoFactory',
    function (
      $location,
      $filter,
      $uibModal,
      sweetAlert,
      AuthFactory,
      ProdutoFactory,
      ParametrizacaoFactory,
      PedidoFactory
    ) {
      var $public = this;
      var $private = {};

      $public.AtualizarQuantidade = function (itemSelecionado) {
        if ($public.Loading) {
          return;
        }

        $public.Loading = true;

        var vm = {
          ProdutoID: itemSelecionado.ProdutoID,
          Quantidade: itemSelecionado.Quantidade,
        };
        ProdutoFactory.AtualizarQuantidade(vm)
          .then(function () {
            $public.Loading = false;
            $public.Buscar();
          })
          .catch(function () {
            $public.Loading = false;
            // Sweetalert
          });
      };

      $public.AbrirModalExcluir = function (itemSelecionado) {
        sweetAlert.swal.queue([
          {
            title: 'Atenção!',
            html:
              'Deseja remover o item <strong><u>' +
              itemSelecionado.Produto.Descricao +
              '</u></strong>?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#F44336',
            confirmButtonText: 'Sim, remover!',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            preConfirm: function () {
              $(sweetAlert.swal.getCancelButton()).hide();

              return ProdutoFactory.Delete(itemSelecionado.ProdutoID)
                .then(function () {
                  sweetAlert.swal.insertQueueStep({
                    title: 'Removido!',
                    text: 'Produto removido com sucesso',
                    type: 'success',
                    timer: 2000,
                  });

                  $public.Buscar();
                })
                .catch(function () {
                  sweetAlert.swal.insertQueueStep({
                    title: 'Atenção!',
                    text:
                      'Ocorreu um problema inesperado, entre em contato com o suporte técnico',
                    type: 'error',
                  });
                });
            },
          },
        ]);
      };

      $public.AbrirModalFinalizar = function () {
        var modalInstance = $uibModal.open({
          templateUrl: '/Views/Pedido/ModalFinalizar.html',
          controller: 'PedidoFinalizarController',
          controllerAs: 'ctrl',
          size: 'md',
          backdrop: 'static',
          keyboard: false,
          resolve: {
            Pedido: $public.Pedido,
          },
        });

        modalInstance.result.then(function () {
          $location.path('/pedido/enviado');
        });
      };

      $public.Imprimir = function () {
        var subTitulo =
          AuthFactory.GetUserInfo().Nome +
          ' - CNPJ: ' +
          $filter('brCnpj')(AuthFactory.GetUserInfo().CNPJ) +
          ' - Email: ' +
          AuthFactory.GetUserInfo().Email;

        ParametrizacaoFactory.GetPDFMakeDocument('Orçamento', subTitulo).then(
          function (response) {
            var docDefinition = response.data;
            docDefinition.footer = function (currentPage, pageCount) {
              return {
                style: 'pageFooter',
                columns: [
                  {
                    text: `Página ${currentPage} / ${pageCount}`,
                    alignment: 'left',
                  },
                  {
                    text: new Date().toLocaleString(),
                    alignment: 'right',
                  },
                ],
              };
            };
            docDefinition.content.push({
              text: 'Produtos',
              bold: true,
            });
            var tc = 50;
            var tableContent = {
              layout: 'lightHorizontalLines',
              style: 'table',
              table: {
                headerRows: 1,
                widths: ['auto', '*', tc, tc, tc, tc, tc, tc],
                body: [
                  [
                    {
                      text: '\nCódigo',
                      style: 'tableHeader',
                      alignment: 'center',
                    },
                    { text: '\nDescrição', style: 'tableHeader' },
                    {
                      text: 'Valor\nunitário',
                      style: 'tableHeader',
                      alignment: 'center',
                    },
                    {
                      text: 'Alíquota\nIVA',
                      style: 'tableHeader',
                      alignment: 'center',
                    },
                    {
                      text: '\nPeso',
                      style: 'tableHeader',
                      alignment: 'center',
                    },
                    {
                      text: '\nQuantidade',
                      style: 'tableHeader',
                      alignment: 'center',
                    },
                    {
                      text: '\nDesconto',
                      style: 'tableHeader',
                      alignment: 'center',
                    },
                    {
                      text: 'Valor\nTotal',
                      style: 'tableHeader',
                      alignment: 'center',
                    },
                  ],
                ],
              },
            };

            angular.forEach($public.Pedido.Itens, function (item) {
              tableContent.table.body.push([
                {
                  text: item.Produto.Codigo,
                  style: 'tableData',
                  alignment: 'center',
                },
                { text: item.Produto.Descricao, style: 'tableData' },
                {
                  text: $filter('currency')(item.ValorUnitario, 'R$ '),
                  style: 'tableData',
                  alignment: 'center',
                },
                {
                  text: $filter('number')(item.IVA, 2) + '%',
                  style: 'tableData',
                  alignment: 'center',
                },
                {
                  text: $filter('number')(item.Produto.Peso, 4),
                  style: 'tableData',
                  alignment: 'center',
                },
                {
                  text: item.Quantidade,
                  style: 'tableData',
                  alignment: 'center',
                },
                {
                  text: $filter('number')(item.Desconto, 2) + '%',
                  style: 'tableData',
                  alignment: 'center',
                },
                {
                  text: $filter('currency')(item.ValorTotalComDesconto, 'R$ '),
                  style: 'tableData',
                  alignment: 'center',
                },
              ]);
            });

            docDefinition.content.push(tableContent);
            docDefinition.content.push({
              text:
		'\nValor do desconto atual, concedido para itens que se aplica (%): ' + $public.Pedido.Desconto.toFixed(2), 
              style: 'tableHeader',
              bold: true,
            });
            docDefinition.content.push({
              text:
		'\nValor Total do Pedido sem desconto e sem Taxa Financeira (R$) ' + $public.Pedido.TotalSemDesconto.toFixed(2),
              style: 'tableHeader',
              bold: true,
            });
            docDefinition.content.push({
              text:
		'\nValor total do pedido com desconto, sem IVA e sem TAXA FINANCEIRA (R$): ' + $public.Pedido.TotalComDesconto.toFixed(2),
              style: 'tableHeader',
              bold: true,
            });

            pdfMake.createPdf(docDefinition).open();
          }
        );
      };

      $public.Buscar = function () {
        if ($public.Loading) {
          return;
        }

        $public.Loading = true;

        PedidoFactory.GetPedido({ Include: 'Produtos' })
          .then(function (response) {
            $public.Pedido = response.data;
            $public.Loading = false;
          })
          .catch(function () {
            $public.Loading = false;
          });
      };

      $private.Init = function () {
        $public.Pedido = undefined;
        $public.Buscar();
      };

      $private.Init();
    },
  ]);

  app.controller('PedidoFinalizarController', [
    '$uibModalInstance',
    'sweetAlert',
    'CondicaoPagamentoFactory',
    'PedidoFactory',
    'Pedido',
    function (
      $uibModalInstance,
      sweetAlert,
      CondicaoPagamentoFactory,
      PedidoFactory,
      Pedido
    ) {
      var $public = this;
      var $private = {};

      $public.FecharModal = function () {
        $uibModalInstance.dismiss();
      };

      $public.Enviar = function () {
        var retorno = {
          onSuccess: function () {
            sweetAlert.swal({
              type: 'success',
              title: 'Sucesso!',
              text: 'Pedido enviado com sucesso!',
              timer: 2000,
            });

            $uibModalInstance.close();
          },
          onError: function () {
            sweetAlert.swal(
              'Tivemos um problema!',
              'Por favor, informe ao desenvolvedor.',
              'error'
            );
          },
        };

        PedidoFactory.Finalizar($public.Pedido)
          .then(retorno.onSuccess)
          .catch(retorno.onError);
      };

      $private.Init = function () {
        $public.Pedido = angular.copy(Pedido);
        $public.Pedido.AutorizaEnvioParcial = true;

        $public.EnvioParcial = [
          { Name: 'Sim', Value: true },
          { Name: 'Não', Value: false },
        ];

        CondicaoPagamentoFactory.Get()
          .then(function (response) {
            $public.CondicoesPagamento = response.data;
            $public.Loading = false;
          })
          .catch(function () {
            $public.Loading = false;
          });
      };

      $private.Init();
    },
  ]);

  app.controller('PedidoEnviadoController', [
    function () {
      var $public = this;
      var $private = {};
    },
  ]);
})();

// eslint-disable-next-line no-unused-vars
(function (undefined) {
  'use strict';

  var app = angular.module('VebiApp');

  //Rotas
  app.config([
    '$routeProvider',
    function ($routeProvider) {
      $routeProvider.when('/produto', {
        title: 'Busca de peças',
        controller: 'ProdutoController',
        controllerAs: 'ctrl',
        templateUrl: 'Views/Produto/index.html',
        authorize: true,
        roles: [],
      });
    },
  ]);

  //Controllers
  app.controller('ProdutoController', [
    '$location',
    '$uibModal',
    'sweetAlert',
    'ProdutoFactory',
    function ($location, $uibModal, sweetAlert, ProdutoFactory) {
      var $public = this;
      var $private = {};

      $public.AbrirModalFoto = function (itemSelecionado) {
        $uibModal.open({
          templateUrl: '/Views/Produto/ModalFoto.html',
          controller: 'ProdutoModalFotoController',
          controllerAs: 'ctrl',
          size: 'md',
          resolve: {
            Produto: itemSelecionado,
          },
        });
      };

      $public.AbrirModalAdicionar = function (itemSelecionado) {
        var modalInstance = $uibModal.open({
          templateUrl: '/Views/Produto/ModalAdicionar.html',
          controller: 'ProdutoModalAdicionarController',
          controllerAs: 'ctrl',
          size: 'sm',
          backdrop: 'static',
          keyboard: false,
          resolve: {
            Produto: itemSelecionado,
          },
        });

        modalInstance.result.then(function () {
          $location.path('/Pedido');
        });
      };

      $public.Buscar = function () {
        if ($public.PaginationConfig.Loading) {
          return;
        }

        $public.PaginationConfig.Loading = true;

        ProdutoFactory.GetByFiltro($public.PaginationConfig.Paginacao)
          .then(function (response) {
            $public.Produtos = response.data.Registros;
            $public.PaginationConfig.Paginacao.OrderBy = response.data.OrderBy;
            $public.PaginationConfig.Paginacao.Page = response.data.Page;
            $public.PaginationConfig.Paginacao.TotalRegistros =
              response.data.TotalRegistros;
            $public.PaginationConfig.Paginacao.Max = response.data.Max;
            $public.PaginationConfig.Loading = false;
          })
          .catch(function () {
            $public.PaginationConfig.Loading = false;
          });
      };

      $private.Init = function () {
        $public.Produtos = [];
        $public.PaginationConfig = {
          Loading: false,
          Paginacao: {
            Search: '',
            SearchField: 'Codigo',
            OrderBy: 'Codigo',
            Max: 20,
            Page: 1,
            TotalRegistros: 0,
            Situacao: 1,
            NumPages: undefined,
            Include: [],
          },
          Action: $public.Buscar,
        };

        $public.Buscar();
      };

      $private.Init();
    },
  ]);

  app.controller('ProdutoModalFotoController', [
    '$uibModalInstance',
    'Produto',
    function ($uibModalInstance, Produto) {
      var $public = this;
      var $private = {};

      $public.FecharModal = function () {
        $uibModalInstance.dismiss();
      };

      $private.Init = function () {
        $public.Produto = Produto;
      };

      $private.Init();
    },
  ]);

  app.controller('ProdutoModalAdicionarController', [
    '$uibModalInstance',
    'sweetAlert',
    'ProdutoFactory',
    'Produto',
    function ($uibModalInstance, sweetAlert, ProdutoFactory, Produto) {
      var $public = this;
      var $private = {};

      $public.TipoVisualizacao = { Confirmacao: 0, Formulario: 1 };

      $public.FecharModal = function () {
        $uibModalInstance.dismiss();
      };

      $public.Finalizar = function () {
        $uibModalInstance.close();
      };

      $public.Enviar = function () {
        $public.Loading = true;
        const vm = {
          ProdutoID: Produto.ProdutoID,
          Quantidade: $public.Quantidade,
        };

        ProdutoFactory.Adicionar(vm)
          .then(function () {
            $public.Visualizacao = $public.TipoVisualizacao.Confirmacao;
            $public.Loading = false;
          })
          .catch(function () {
            sweetAlert.swal(
              'Atenção!',
              'Ocorreu um erro ao adicionar o item!',
              'error'
            );
            $public.Loading = false;
          });
      };

      $private.Init = function () {
        $public.Loading = false;
        $public.Visualizacao = $public.TipoVisualizacao.Formulario;
        $public.Produto = Produto;
        $public.Quantidade = 1;
      };
      $private.Init();
    },
  ]);
})();

(function (undefined) { // eslint-disable-line no-unused-vars
  'use strict';

  var app = angular.module('VebiApp');

  //Rotas
  app.config(['$routeProvider', function ($routeProvider) {
    $routeProvider.when('/acesso-negado', {
      title: 'Acesso negado',
      controller: 'AcessoNegadoController',
      controllerAs: 'ctrl',
      templateUrl: '/Views/Shared/AcessoNegado.html',
      authorize: true
    });
  }]);

  //Controllers
  app.controller('AcessoNegadoController', [function () {
    var $private = {};

    $private.Init = function() { };

    $private.Init();
  }]);
})();

// eslint-disable-next-line no-unused-vars
(function (undefined) {
  'use strict';

  var app = angular.module('VebiApp');

  //Rotas
  app.config([
    '$routeProvider',
    function ($routeProvider) {
      $routeProvider.when('/home', {
        title: 'Página inicial',
        controller: 'HomeController',
        controllerAs: 'ctrl',
        templateUrl: 'Views/Home/Inicio.html',
        authorize: true,
        roles: [],
      });
    },
  ]);

  //Controllers
  app.controller('HomeController', [
    '$location',
    function ($location) {
      $location.path('/produto');
    },
  ]);
})();

// eslint-disable-next-line no-unused-vars
(function (undefined) {
  'use strict';

  var app = angular.module('VebiApp');

  //Controllers
  app.controller('MenuNavbarController', [
    'sweetAlert',
    '$location',
    'AuthFactory',
    'BrowserNotificationFactory',
    function (sweetAlert, $location, AuthFactory, BrowserNotificationFactory) {
      var $public = this;
      var $private = {};

      $public.Logoff = function () {
        AuthFactory.Logout().then(function () {
          $location.path('/login');
        });
      };

      $private.Init = function () {
        $public.Nome = AuthFactory.GetUserInfo().Nome;

        AuthFactory.OnRefreshToken(function (minutes) {
          if (minutes <= 5) {
            var msg = 'Sua sessão se encerra em ' + minutes + ' minutos.';

            sweetAlert.swal({ title: 'Atenção!', text: msg, type: 'warning' });
            BrowserNotificationFactory.ShowNotification('Atenção', {
              body: msg,
            });
          }
        });
      };

      $private.Init();
    },
  ]);

  //Controllers
  app.controller('MenuSidebarController', [
    '$location',
    '$timeout',
    'MenuFactory',
    'AuthFactory',
    function ($location, $timeout, MenuFactory, AuthFactory) {
      var $public = this;
      var $private = {};

      $public.Logoff = function () {
        AuthFactory.Logout().then(function () {
          $location.path('/login');
        });
      };

      $private.MontaMenu = function () {
        MenuFactory.GetByOperador().then(function (response) {
          $public.Menus = response.data;
          //Aplica js e css em jquery do menu após carregamento do menu
          $timeout(function () {
            initSidebar(); // eslint-disable-line no-undef
            initScroll(); // eslint-disable-line no-undef
            initMiniSidebar(); // eslint-disable-line no-undef
          }, 10);
        });
      };

      // $public.MontaSubItensMenu = function (itens, root) {
      //   var final = '';

      //   for (var i = 0; i < itens.length; i++) {
      //     var item = itens[i];

      //     final += item.MenusFilhos.length ?
      //       `<li>
      //         <a href="" role="menuitem" title="${item.Descricao}">
      //             <i class="fa ${item.Icone} fa-lg"></i>
      //             <span>${item.Descricao}</span>
      //         </a>
      //         ${$public.MontaSubItensMenu(item.MenusFilhos, false)}
      //       </li>
      //     ` :
      //       `<li>
      //         <a href="${item.Url}" role="menuitem" title="${item.Descricao}">
      //             <i class="fa ${item.Icone} fa-lg"></i>
      //             <span>${item.Descricao}</span>
      //         </a>
      //      </li>`;
      //   }

      //   return root ? '<li class="navigation-header"><span>Principal</span><i class="icon-menu" title="" data-original-title="Principal"></i></li>' + final : `<ul>${final}</ul>`;
      // };

      $private.Init = function () {
        $private.MontaMenu();
      };

      $private.Init();
    },
  ]);
})();

(function (undefined) { // eslint-disable-line no-unused-vars
  'use strict';

  var app = angular.module('VebiApp');

  app.directive('enterTab', function () {
    return function (scope, element, attrs) { // eslint-disable-line no-unused-vars
      element.bind('keydown keypress', function (event) {
        if (event.which === 13) {
          event.preventDefault();
          var fields = $(this).parents('form:eq(0),body').find('input, textarea, select');
          var index = fields.index(this);
          if (index > -1 && (index + 1) < fields.length){
            fields.eq(index + 1).focus();
          }
        }
      });
    };
  });

})();

(function (undefined) { // eslint-disable-line no-unused-vars
  'use strict';

  var app = angular.module('VebiApp');

  app.directive('brabaoValidator', ['$parse', 'sweetAlert', function ($parse, sweetAlert) {
    return {
      priority: 0,
      restrict: 'A',
      require: '?ngSubmit',
      scope: true,
      link: function (scope, element, attrs) {
        var form = $parse(attrs.name)(scope);

        element.off('submit'); //Cancel ng-submit

        //submit
        element.on('submit', function (e) {
          form.$setSubmitted();

          if (form.$invalid) {
            e.preventDefault();

            return sweetAlert.swal('Atenção!', 'Verifique o preenchimento de todos os campos.', 'warning')
              .then(function () { element.find('.ng-invalid:eq(0)').focus(); });
          }

          //form.$setPristine();
          scope.$apply(attrs.ngSubmit); //call ng-submit
        });

        //onbeforeunload
        /*
                scope.$watch(function () {
                    window.onbeforeunload = form.$dirty ? function () { return "É possível que as alterações feitas não sejam salvas"; } : null;
                });
                */
      }
    };
  }]);

  app.directive('validateErrorFor', ['$parse', function ($parse) {
    return {
      restrict: 'A',
      scope: true,
      link: function (scope, element, attrs, ctrl) { // eslint-disable-line no-unused-vars
        scope.$watch(
          function () {
            var formElement = element.parents('form:eq(0)');
            var formName = formElement.attr('name');

            var form = $parse(formName)(scope);
            if (!form) { return true; }  //invalid

            var item = $parse(formName + '.' + attrs.validateErrorFor)(scope);
            if (!item) { return true; } //invalid

            return item.$invalid && (item.$dirty || form.$submitted);
          },
          function (isInvalid) {
            element.toggleClass('has-error', isInvalid);
          }
        );
      }
    };
  }]);

  app.directive('errorMessageFor', ['$parse', function ($parse) {
    return {
      restrict: 'A',
      templateUrl: 'Views/Shared/ngMessages/Index.html',
      scope: true,
      transclude: true,
      link: function (scope, element, attrs, ctrl) { // eslint-disable-line no-unused-vars
        scope.$watch(
          function () {
            var formElement = element.parents('form:eq(0)');
            var formName = formElement.attr('name');
            scope.Form = $parse(formName)(scope);
            scope.Field = $parse(attrs.errorMessageFor)(scope);
          }
        );
      }
    };
  }]);

  app.directive('labelFor', [function () {
    return {
      restrict: 'E',
      scope: { name: '@', description: '@' },
      templateUrl: 'Views/Shared/ngMessages/Label.html',
      link: function (scope, element, attrs, ctrl) { // eslint-disable-line no-unused-vars
        scope.required = attrs.required !== undefined;
      }
    };
  }]);
})();

(function (undefined) { // eslint-disable-line no-unused-vars
  'use strict';

  var app = angular.module('VebiApp');

  app.directive('onReadFile', ['$parse', function ($parse) {
    return {
      restrict: 'A',
      scope: false,
      link: function(scope, element, attrs) {
        var fn = $parse(attrs.onReadFile);

        element.on('change', function(onChangeEvent) {
          var reader = new FileReader();

          var fileName = onChangeEvent.target.files[0].name;

          reader.onload = function(onLoadEvent) {
            scope.$apply(function() {
              fn(scope, { $fileName: fileName, $fileContent: onLoadEvent.target.result });
            });
          };

          reader.readAsText((onChangeEvent.srcElement || onChangeEvent.target).files[0]);
        });
      }
    };
  }]);

})();

(function (undefined) { // eslint-disable-line no-unused-vars
  'use strict';

  var app = angular.module('VebiApp');

  app.directive('scrollDetector', function () {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        var raw = element[0];
        element.bind('scroll', function () {
          if (raw.scrollTop + raw.offsetHeight >= raw.scrollHeight) {
            scope.$apply(attrs.scrollDetector);
          }
        });
      }
    };
  });

})();

(function (undefined) { // eslint-disable-line no-unused-vars
  'use strict';

  var app = angular.module('VebiApp');

  app.directive('includeReplace', function () {
    return {
      require: 'ngInclude',
      restrict: 'A', /* optional */
      link: function (scope, el) {
        el.replaceWith(el.children());
      }
    };
  });

})();

(function (undefined) {
  // eslint-disable-line no-unused-vars
  'use strict';

  var app = angular.module('VebiApp');

  var searchFieldTypes = {
    String: 0,
    Integer: 1,
    Date: 2,
    Select: 3,
    CEP: 4,
    CPF: 5,
    CNPJ: 6,
    CPFCNPJ: 7,
    Moeda: 8,
    Mask: 9,
    Decimal: 10,
    Percentual: 11,
  };

  app.directive('pagination', [
    function () {
      return {
        restrict: 'A',
        scope: { config: '=' },
        controller: [
          '$scope',
          function ($scope) {
            var $public = this;
            var $private = { TableHeadersItens: [] };

            $public.Config = $scope.config;

            $public.RegisterTableHeader = function (tableHeaderItem) {
              if (
                $scope.config.Paginacao.OrderBy === tableHeaderItem.field ||
                $scope.config.Paginacao.OrderBy === '-' + tableHeaderItem.field
              ) {
                tableHeaderItem.active = true;
                tableHeaderItem.OrderType = $scope.config.Paginacao.OrderBy.startsWith(
                  '-'
                )
                  ? 1
                  : 0;
              }

              $private.TableHeadersItens.push(tableHeaderItem);
            };

            $public.Ordenar = function (tableHeaderItem) {
              if (tableHeaderItem) {
                $scope.config.Paginacao.OrderBy =
                  $scope.config.Paginacao.OrderBy === tableHeaderItem.field
                    ? '-' + tableHeaderItem.field
                    : tableHeaderItem.field;

                $private.TableHeadersItens.forEach(function (item) {
                  item.active =
                    $scope.config.Paginacao.OrderBy === item.field ||
                    $scope.config.Paginacao.OrderBy === '-' + item.field;
                  item.OrderType = $scope.config.Paginacao.OrderBy.startsWith(
                    '-'
                  )
                    ? 1
                    : 0;
                });
              }

              $scope.config.Action();
            };
          },
        ],
      };
    },
  ]);

  function MontaDirectivaSortItem(type) {
    return {
      restrict: 'A',
      scope: { field: '@' },
      require: '^pagination',
      transclude: true,
      templateUrl: 'Views/Shared/DirectivePagination/SortItem.html',
      link: function (scope, element, attrs, ctrl) {
        scope.type = type;

        scope.Config = ctrl.Config;

        ctrl.RegisterTableHeader(scope);

        scope.Toggle = function () {
          if (scope.Config.Loading) {
            return;
          }

          ctrl.Ordenar(scope);
        };
      },
    };
  }

  app.directive('sortItemNumeric', [
    function () {
      return MontaDirectivaSortItem('numeric');
    },
  ]);
  app.directive('sortItemAlpha', [
    function () {
      return MontaDirectivaSortItem('alpha');
    },
  ]);

  app.directive('paginationInfo', [
    function () {
      return {
        restrict: 'A',
        scope: { totalspan: '@' },
        require: '^pagination',
        templateUrl: 'Views/Shared/DirectivePagination/Info.html',
        link: function (scope, element, attrs, ctrl) {
          scope.Config = ctrl.Config;
        },
      };
    },
  ]);

  app.directive('paginationPageItens', [
    function () {
      return {
        restrict: 'A',
        require: '^pagination',
        templateUrl: 'Views/Shared/DirectivePagination/PageItens.html',
        link: function (scope, element, attrs, ctrl) {
          scope.Config = ctrl.Config;
          scope.RegistrosPorPagina = [10, 20, 30, 40, 50];
          scope.Buscar = function () {
            scope.Config.Paginacao.Page = 1;
            ctrl.Ordenar();
          };
        },
      };
    },
  ]);

  app.directive('paginationButtons', [
    '$window',
    function ($window) {
      return {
        restrict: 'A',
        scope: { totalspan: '@' },
        require: '^pagination',
        templateUrl: 'Views/Shared/DirectivePagination/Buttons.html',
        link: function (scope, element, attrs, ctrl) {
          scope.Config = ctrl.Config;

          var onResize = function () {
            if ($window.innerWidth < 576) {
              scope.Config.PaginationMaxSize = 2;
            } //xs
            if ($window.innerWidth >= 576 && $window.innerWidth < 768) {
              scope.Config.PaginationMaxSize = 6;
            } //sm
            if ($window.innerWidth >= 768) {
              scope.Config.PaginationMaxSize = 10;
            } //md, lg, xl
          };

          angular.element($window).bind('resize', function () {
            scope.$apply(onResize);
          });

          onResize();

          scope.Buscar = function () {
            ctrl.Ordenar();
          };
        },
      };
    },
  ]);

  app.directive('paginationSearch', [
    '$timeout',
    function ($timeout) {
      return {
        restrict: 'AE',
        scope: { totalspan: '@' },
        require: '^pagination',
        templateUrl: 'Views/Shared/DirectivePagination/Search.html',
        transclude: true,
        replace: true,
        controller: [
          '$scope',
          function ($scope) {
            var $public = this;

            $scope.searchFields = [];
            $scope.currentItem = undefined;
            $scope.Config = angular.copy($scope.Config);

            $public.PaginationConfig = $scope.$parent.ctrl.PaginationConfig;

            $public.Register = function (searchField, defaultField) {
              // eslint-disable-line no-unused-vars
              $scope.searchFields.push(searchField);
              //if(defaultField) { $scope.currentItem = searchField; }
              if (
                $scope.$parent.ctrl.PaginationConfig.Paginacao.SearchField ===
                searchField.field
              ) {
                $scope.currentItem = searchField;
              }
            };

            $public.LimparSearchItem = function () {
              $scope.$parent.ctrl.PaginationConfig.Paginacao.Search = undefined;
            };
          },
        ],
        link: function (scope, element, attrs, ctrl) {
          scope.Config = ctrl.Config;
          scope.searchFieldTypes = searchFieldTypes;

          var _timeout;

          scope.onSearchFieldChange = function (searchItem) {
            if (searchItem === scope.currentItem) {
              return;
            }

            scope.Config.Paginacao.Search = undefined;

            scope.currentItem = searchItem;
            scope.Config.Paginacao.SearchField = searchItem.field;
            if (
              scope.Config.Paginacao.Search &&
              scope.Config.Paginacao.Search.length
            ) {
              scope.Buscar();
            }
          };

          scope.Buscar = function () {
            if (_timeout) {
              $timeout.cancel(_timeout);
            }

            _timeout = $timeout(function () {
              scope.isDatepickerOpen = false;
              ctrl.Ordenar();

              _timeout = null;
            }, 1000);
          };
        },
      };
    },
  ]);

  function MontaDirectivaSearchField(type) {
    return {
      restrict: 'AE',
      require: '^paginationSearch',
      controller: [
        '$scope',
        function ($scope) {
          var $public = this;
          $scope.searchItem = { options: [] };

          $public.UpdateOptions = function (values) {
            $scope.searchItem.options.push(values);
          };
        },
      ],
      link: function (scope, element, attrs, ctrl) {
        scope.searchItem.field = attrs.field;
        scope.searchItem.name = attrs.name;
        scope.searchItem.type = type;

        if (scope.searchItem.type === searchFieldTypes.Date) {
          scope.searchItem.isDatepickerOpen = false;
          scope.searchItem.datepickerOptions = {
            showWeeks: false,
            minMode: attrs.mode || 'day',
          };
          scope.searchItem.datepickerFormat = attrs.format || 'dd/MM/yyyy';
        }

        if (scope.searchItem.type === searchFieldTypes.Mask) {
          scope.searchItem.mask = attrs.mask;
        }

        if (scope.searchItem.type === searchFieldTypes.Decimal) {
          scope.searchItem.decimalSize = attrs.decimalSize;
        }

        if (!attrs.name) {
          scope.searchItem.name = scope.searchItem.field;
        }

        var defaultItem = true; //scope.$parent.Config.Paginacao === scope.searchItem.field;

        ctrl.Register(scope.searchItem, defaultItem);
      },
    };
  }

  app.directive('searchField', [
    function () {
      return MontaDirectivaSearchField(searchFieldTypes.String);
    },
  ]);
  app.directive('searchFieldString', [
    function () {
      return MontaDirectivaSearchField(searchFieldTypes.String);
    },
  ]);
  app.directive('searchFieldInteger', [
    function () {
      return MontaDirectivaSearchField(searchFieldTypes.Integer);
    },
  ]);
  app.directive('searchFieldDecimal', [
    function () {
      return MontaDirectivaSearchField(searchFieldTypes.Decimal);
    },
  ]);
  app.directive('searchFieldDate', [
    function () {
      return MontaDirectivaSearchField(searchFieldTypes.Date);
    },
  ]);
  app.directive('searchFieldSelect', [
    function () {
      return MontaDirectivaSearchField(searchFieldTypes.Select);
    },
  ]);
  app.directive('searchFieldCep', [
    function () {
      return MontaDirectivaSearchField(searchFieldTypes.CEP);
    },
  ]);
  app.directive('searchFieldCpf', [
    function () {
      return MontaDirectivaSearchField(searchFieldTypes.CPF);
    },
  ]);
  app.directive('searchFieldCnpj', [
    function () {
      return MontaDirectivaSearchField(searchFieldTypes.CNPJ);
    },
  ]);
  app.directive('searchFieldCpfCnpj', [
    function () {
      return MontaDirectivaSearchField(searchFieldTypes.CPFCNPJ);
    },
  ]);
  app.directive('searchFieldMoeda', [
    function () {
      return MontaDirectivaSearchField(searchFieldTypes.Moeda);
    },
  ]);
  app.directive('searchFieldPercentual', [
    function () {
      return MontaDirectivaSearchField(searchFieldTypes.Percentual);
    },
  ]);
  app.directive('searchFieldMask', [
    function () {
      return MontaDirectivaSearchField(searchFieldTypes.Mask);
    },
  ]);

  app.directive('searchFieldSelectOption', [
    function () {
      return {
        restrict: 'AE',
        require: '^searchFieldSelect',
        link: function (scope, element, attrs, ctrl) {
          var searchItem = { Name: attrs.name, Value: attrs.value };
          ctrl.UpdateOptions(searchItem);
        },
      };
    },
  ]);

  app.directive('searchFieldSelectEnum', [
    function () {
      return {
        restrict: 'AE',
        require: '^paginationSearch',
        scope: { enum: '=' },
        link: function (scope, element, attrs, ctrl) {
          var searchItem = {
            field: attrs.field,
            name: attrs.name,
            type: searchFieldTypes.Select,
            options: [],
          };

          var _name = attrs.label || 'Name';
          var _value = attrs.value || 'Value';

          //Iniciar os itens
          angular.forEach(scope.enum, function (item) {
            searchItem.options.push({ Name: item[_name], Value: item[_value] });
          });

          //Itens rest
          scope.$watch('enum', function () {
            searchItem.options = [];

            // var resultSearch = undefined;

            angular.forEach(scope.enum, function (item) {
              // if (ctrl.PaginationConfig.Search && item[_value] === ctrl.PaginationConfig.Search) {
              //   resultSearch = item[_value];
              // }
              searchItem.options.push({
                Name: item[_name],
                Value: item[_value],
              });
            });

            // if (!resultSearch) { ctrl.LimparSearchItem(); }
          });

          if (!attrs.name) {
            searchItem.name = searchItem.field;
          }

          ctrl.Register(searchItem);
        },
      };
    },
  ]);
})();

(function (undefined) { // eslint-disable-line no-unused-vars
  'use strict';

  var app = angular.module('VebiApp');

  app.filter('BrCpf', function () {
    return function (item) {
      if (!item) {
        return item;
      }

      return item.slice(0,3) + '.' + item.slice(3,6) + '.' + item.slice(6,9) + '-' + item.slice(9,11);

    };
  });

})();

(function (undefined) { // eslint-disable-line no-unused-vars
  'use strict';

  var app = angular.module('VebiApp');

  app.filter('padRight', function () {
    return function (item, tamanho, caracter) {
      if (!item) {
        return item;
      }

      if (item.length >= tamanho) {
        return item.substring(0, tamanho);
      }

      caracter = caracter || ' - ';
      var espacos = new Array(tamanho - item.length + 1).join(caracter);

      return (item + espacos);

    };
  });

})();
